<template>
  <div class="Tooltip">
    <slot name="default" />
    <span
      class="Tooltiptext"
      :class="`direction-${direction}`"
      ref="tooltip"
      :style="{
        marginLeft: `-${direction === 'left' ? width : width / 2}px`,
        marginBottom: `-${height / 2 - 1}px`,
      }"
    >
      <slot name="title" />
    </span>
  </div>
</template>
<script>
export default {
  props: {
    direction: {
      type: String,
      validator(value) {
        return [undefined, 'top', 'left'].includes(value)
      },
    },
  },
  data() {
    return {
      width: 0,
      height: 0,
    }
  },
  mounted() {
    const rect = this.$refs.tooltip.getBoundingClientRect()
    this.width = rect.width
    this.height = rect.height
  },
  methods: {},
}
</script>
