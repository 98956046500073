<template>
  <div
    class="FormProgress FormProgressCircle"
    ref="formprogress"
    :class="[{ circular }, showPercentage && labelPosition]"
  >
    <Tooltip direction="left">
      <vue-ellipse-progress
        :size="30"
        :color="barColor"
        thickness="12%"
        :progress="percent"
        legendClass="progress-bar-legend"
        @click.native="reset"
      >
        {{ percentDisplayText }}
      </vue-ellipse-progress>
      <template slot="title">Click to reset progress</template>
    </Tooltip>
    <div v-if="showPercentage && labelPosition !== 'inside'">
      {{ percentDisplayText }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueEllipseProgress from 'vue-ellipse-progress'
import Tooltip from './helpers/Tooltip.vue'
// import AntTooltip from 'ant-design-vue/lib/tooltip'
Vue.use(VueEllipseProgress)

export default {
  name: 'CircularFormProgress',
  components: {
    Tooltip,
  },
  inject: ['_filterByConditions', '_uniqueByKey', '_getFlowPages'],
  props: {
    form: Object,
    page: Object,
    component: Object,
    userData: Object,
  },
  data() {
    return { primaryColor: undefined }
  },
  computed: {
    pages() {
      return this._getFlowPages()
        .reduce(this._uniqueByKey, [])
        .filter(this._filterByConditions)
    },
    currentPageIndex() {
      return this.pages.findIndex(p => p.id === this.page.id)
    },
    showPercentage() {
      const componentVal = this.component && this.component.showProgressPercentage
      if (componentVal === undefined) {
        const formVal = this.form && this.form.showProgressPercentage
        return formVal //=== undefined ? true : formVal
      }
      return componentVal
    },
    labelPosition() {
      const componentVal = this.component && this.component.progressBarLabelPosition
      if (componentVal === undefined) {
        const formVal = this.form && this.form.progressBarLabelPosition
        return formVal === undefined ? 'left' : formVal
      }
      return componentVal
    },
    circular() {
      const type =
        (this.component && this.component.progressBarType) ||
        (this.form && this.form.progressBarType)
      return type === undefined ? true : type === 'circle'
    },
    barColor() {
      const styles = (this.form && this.form.styles) || {}
      return (
        (styles &&
          styles['.FormProgress .bar'] &&
          styles['.FormProgress .bar']['background-color']) ||
        this.primaryColor
      )
    },
    percentageType() {
      const componentVal = this.component && this.component.percentageType
      if (componentVal === undefined) {
        const formVal = this.form && this.form.percentageType
        return formVal === undefined ? 'percent-complete' : formVal
      }
      return componentVal
    },
    startAtOne() {
      const componentVal = this.component && this.component.progressBarStartAtOne
      if (componentVal === undefined) {
        const formVal = this.form && this.form.progressBarStartAtOne
        return formVal
      }
      return componentVal
    },
    percentThreshhold() {
      return this.percent > 10
    },
    percentDisplayText() {
      if (!this.form) return ''
      const percentageType = this.percentageType

      switch (percentageType) {
        case 'fractional': {
          const startAtOne = this.startAtOne
          if (startAtOne) {
            const completeText =
              (this.component && this.component.progressBarCompleteText) ||
              (this.form && this.form.progressBarCompleteText)
            const pageIndex = this.currentPageIndex + 1
            return pageIndex === this.pages.length
              ? completeText || ''
              : `${pageIndex} / ${this.pages.length > 1 ? this.pages.length - 1 : 1}`
          }
          return `${this.currentPageIndex} / ${this.pages.length > 1 ? this.pages.length - 1 : 1}`
        }

        default: {
          return `${this.percent}% complete`
        }
      }
    },
    percent() {
      if (!this.form) return 0
      const percentageType = this.percentageType
      const startAtOne = this.startAtOne

      const currentPageIndex =
        percentageType === 'fractional' && startAtOne
          ? this.currentPageIndex + 1
          : this.currentPageIndex
      const currentTotalPages = this.pages.length > 1 ? this.pages.length - 1 : 1

      return Math.min(Math.round((100 * currentPageIndex) / currentTotalPages), 100)
    },
    hasData() {
      return this.userData && Object.keys(this.userData).length > 1
    },
    formTheme() {
      return this.form && this.form.theme
    },
  },
  mounted() {
    this.setPrimaryColor()
  },
  watch: {
    formTheme: {
      handler() {
        this.setPrimaryColor()
      },
    },
  },
  methods: {
    setPrimaryColor() {
      this.primaryColor = getComputedStyle(this.$refs.formprogress).getPropertyValue(
        '--primary-color'
      )
    },
    reset() {
      this.$emit('reset')
    },
  },
}
</script>
